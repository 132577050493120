import React, { Component } from "react";
import { Card, Button, CardTitle, CardText, Input, Spinner, Label, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { requestServerData } from '../Utils/Config'
import { storeInSession } from '../Utils/SessionStorage'

class adminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillMount() {

    }

    render() {
        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div style={{ paddingTop: '50px', margin: 'auto', width: '300px' }}>
                    <Card style={{ padding: 20 }}>

                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover
                        />
                        <from id="loginfrom" name="loginfrom">
                            <FormGroup>
                                <Label >Admin Email</Label>
                                <Input type="email" name="email" placeholder="Your Email *" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                            </FormGroup>
                            <FormGroup>
                                <Label >Admin Password</Label>
                                <Input type="password" name="password" id="password" placeholder="Your Password *" onChange={(e) => this.handleChange(e)} value={this.state.password} onKeyDown={(e) => this.handleEnterSubmit(e)} />
                            </FormGroup>
                            <Button type="submit" id="submitlogin" style={{ marginTop: 15, width: '100%' }} onClick={() => this.handleLogin()}>Login</Button>
                            {
                                this.state.loading &&
                                <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                                </div>
                            }
                        </from>
                    </Card>
                </div>
            </div>
        );
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleEnterSubmit = (event) => {

        if (event.keyCode === 13) {
            console.log('Enter key pressed')
            document.getElementById("submitlogin").click();
        }
    }

    handleLogin = async () => {

        const { email, password } = this.state;
        this.setState({ loading: true })
        if (email && password) {

            if (email === 'adminvedic') {
                let val_obj = {
                    data: {
                        "username": email,
                        "password": password,
                    },
                    request_type: 'LOGIN',
                    url: 'Account/Login'
                }

                console.log('val_obj', val_obj)

                let response
                response = await requestServerData(val_obj)

                console.log('response ', response)

                if (response.response) {

                    console.log('response', response)
                    // let data = { type: 'admin', token: response.data.data.result.Token }
                    let data = {
                        token: response.data.data.Key.Token,
                        type: 'admin'
                    }

                    await storeInSession('admin', data)
                    this.props.history.push('/contestent-list')
                } else {

                    toast.error('username and password does not match')
                }
            }else{

                toast.error('Please check user name or go to user login page for login')
            }
        } else {

            toast.error("please fill  UserName/Password")
        }
        this.setState({ loading: false })
    }


}

export default withRouter(adminLogin);