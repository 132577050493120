import React, { Component } from "react";
import { Badge, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import AddContestent from '../Admin/AddContestent'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddUserTypeForm from '../Admin/AddUserTypeForm';

class AddUserType extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    handleClick = (key, data) => {
        switch (key) {
        
        case 'add':

            this.props.history.push('/add-user-type-form')
            break;



        case 'close_modal':

            this.setState({
                modalIsActive: false
            })
            // this.handleGetDataForTable()
            break;
        }
    }
    render(){
        return(
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer
                        position="top-center"
                    />
                    <Row>
                        {/* <DashboardMini/> */}

                    </Row>
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Add User Type Details </h3>
                                    <hr />
                                    <Row className="align-items-center">
                                        {/* <h4 className="page-center">
                                            Total :
                                            {this.state.ContestnentDataCount}
                                        </h4> */}
                                        <Col className="d-flex justify-content-end">
                                            <Button className="align-self-right" color="info" onClick={() => this.handleClick('add')}>Add new </Button>
                                            {/* <Button className="align-self-right" color="info" >Add new </Button> */}
                                        </Col>
                                      
                                        
                                     
                                   
                                    </Row>
                              
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div className={'top-main'} style={{ height: window.innerHeight }}>
            <div className="content-wrapper">
                <ToastContainer
                    position="top-center"
                />
            <Modal isOpen={this.state.modalIsActive} toggle={() => this.handleClick('close_modal')}>
            <ModalHeader> Add User Type</ModalHeader>
            <ModalBody>
                {/* <EditContestent/> */}
                <AddUserTypeForm contestentID={this.state.contestentID} handleClose={this.handleClick} />
            </ModalBody>
            <ModalFooter>
                {/* <Button color="primary" onClick={() => { this.handleClick('edit_contestent')}}>Edit Contestent</Button>{' '} */}

                <Button color="secondary" onClick={() => { this.handleClick('close_modal') }}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </div>
        </div>
            
                </div>

            </div>
        )
    }
}
    export default withRouter(AddUserType);