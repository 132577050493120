import axios from "axios";
// let headers = {  "access-control-allow-origin" : "*",'Content-Type': 'application/json'}
import { getFromSession } from '../Utils/SessionStorage'


export function requestServerData(data, type) {
 //let server_url = `http://localhost:8326/api/${data.url}`
//   let server_url = `https://webapi.vedic-wisdom.com/api/${data.url}`
   //  let server_url = `http://webapi.freshsabjibazaar.com/api/${data.url}`
 let server_url = `https://testwebapi.vedic-wisdom.com/api/${data.url}`

    if (data.request_type === 'GET' || data.request_type === "get") {
        
        let header = getHeader(type)
        return new Promise((resolve, reject) => {
            axios
                .get(server_url, {headers:header})
                .then(responseJson => {

                    let value_object
                    if (responseJson.data.StatusCode === 200) {

                        value_object = {
                            response: true,
                            data: responseJson
                        }
                    } else {

                        value_object = {
                            response: false,
                            data: responseJson
                        }
                    }
                    resolve(value_object);

                })
                .catch((error) => {

                    let value_object = {
                        response: false,
                        data: error
                    }
                    reject(value_object);
                })
        })
    }
    else if (data.request_type === 'POST' || data.request_type === "post") {
    
        let header = getHeader(type)
        return new Promise((resolve, reject) => {
            axios
                .post(server_url, data.data, {headers:header})
                .then(responseJson => {
                    console.log('res', responseJson)

                    let value_object
                    if (responseJson.data.StatusCode === 200) {

                        value_object = {
                            response: true,
                            data: responseJson
                        }
                    } else {

                        value_object = {
                            response: false,
                            data: responseJson
                        }
                    }
                    resolve(value_object);

                })
                .catch((error) => {

                    let value_object = {
                        response: false,
                        data: error
                    }
                    reject(value_object);
                })
        })
    }
    else if (data.request_type === 'PUT' || data.request_type === "put") {

        let header = getHeader(type)

        return new Promise((resolve, reject) => {
            axios
                .put(server_url, data.data, {headers:header})
                .then(responseJson => {

                    let value_object
                    if (responseJson.data.StatusCode === 200) {

                        value_object = {
                            response: true,
                            data: responseJson
                        }
                    }
                     else {

                        value_object = {
                            response: false,
                            data: responseJson
                        }
                    }
                    resolve(value_object);

                })
                .catch((error) => {

                    let value_object = {
                        response: false,
                        data: error
                    }
                    reject(value_object);
                })
        })
    }
    else if (data.request_type === 'DELETE' || data.request_type === "delete") {
        
        let header = getHeader(type)

        return new Promise((resolve, reject) => {

            axios
                .delete(server_url + data, {headers:header})
                .then(responseJson => {

                    let value_object
                    if (responseJson.data.StatusCode === 200) {

                        value_object = {
                            response: true,
                            data: responseJson
                        }
                    } else {

                        value_object = {
                            response: false,
                            data: responseJson
                        }
                    }
                    resolve(value_object);

                })
                .catch((error) => {

                    let value_object = {
                        response: false,
                        data: error
                    }
                    resolve(value_object);
                })
        })
    }
    else if (data.request_type === 'login' || data.request_type === "LOGIN") {

        return new Promise((resolve, reject) => {

            axios
                .post(server_url, data.data, { "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST"})
                .then(responseJson => {
                    console.log('res', responseJson)

                    let value_object
                    if (responseJson.data.StatusCode === 200) {

                        value_object = {
                            response: true,
                            data: responseJson
                        }
                    } else {

                        value_object = {
                            response: false,
                            data: responseJson
                        }
                    }
                    resolve(value_object);

                })
                .catch((error) => {

                    let value_object = {
                        response: false,
                        data: error
                    }
                    reject(value_object);
                })
        })
    }

}

export const getHeader = (key) => {

    let token = getFromSession(key)
    
    return {
        "token": token && token.data && token.data.token ||'',
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    }
}