import React from 'react'
import { Progress, Jumbotron, Button } from 'reactstrap';
import { Card, CardTitle, Form, FormGroup, Label, Input, Modal, ModalBody, Spinner } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { requestServerData } from '../Utils/Config'
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class DashboardMini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {           
            ContestentCount: "",
            ApprovedShlokCount: "",           
            PaidAmount: "",          
            UnPaidAmount: "",
            WalletAmount:"",
            TodayApprovedShlokCount: '', 
            statewisedata: [],          
            statedata: [],
           

        }

    }



    componentDidMount(preProps, preState) {
        this.GetAdminDashboardData();
          
    }


    GetAdminDashboardData = async () => {
        this.setState({ loading: true })
        let val_obj = {
            url: `Dashboards/GetAdminDashboardData`,
            data: '',
            request_type: 'get'
        }
        

        let response = await requestServerData(val_obj, 'admin')
        if (response.response) {
           
            var getdata = response.data.data.result;
       
            this.setState({              
              
                ContestentCount: getdata[0].ContestentCount, 
                ApprovedShlokCount: getdata[0].ApprovedShlokCount,               
                PaidAmount: getdata[0].PaidAmount,
                UnPaidAmount: getdata[0].UnPaidAmount,
                WalletAmount: getdata[0].WalletAmount,
                TodayApprovedShlokCount: getdata[0].TodayApprovedShlokCount,             
                
            })
             

        } else {
            alert('Try Again after some time . . .')
        }

    }

    render() {  
      
        return (
           
            <React.Fragment>
                <div style={{ padding: '20px 20px', borderRadius: '5px 20px 5px', backgroundColor: '#fff', color: 'white' }} class="container-fluid">
                    <div class="row content">
                        <div className="well">
                        </div>


                        <div className="col-sm-12">
                            <div style={{ padding: '2px 2px', borderRadius: '5px 20px 5px', backgroundColor: '#00BCD4', color: 'white' }} className="row">

                            <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }} >
                                    <div style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>Total Contestent</h5>
                                        <p>{this.state.ContestentCount}</p>
                                    </div>
                                </div>
                                <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }} >


                                    <div style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>Total Approved Shlok</h5>
                                        <p>{this.state.ApprovedShlokCount}</p>
                                    </div>
                                </div>
                                <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }}>
                                    <div color="primary" style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>Paid Amount</h5>
                                        <p>{this.state.PaidAmount}</p>
                                    </div>
                                </div>

                                
                                <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }} >
                                    <div style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>UnPaid Amount</h5>
                                        <p>{this.state.UnPaidAmount}</p>
                                    </div>
                                </div>
                                <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }} >
                                    <div style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>Wallet Amount</h5>
                                        <p>{this.state.WalletAmount}</p>
                                    </div>
                                </div>
                               

                            <div className="col-sm-2" style={{
                                    flex: " 1 0 18%", margin: "15px", height: "90px", color: "white", display: "flex", align: "center",
                                    justifyContent: "center"
                                }} >

                                    <div style={{ textAlign: 'center', padding: '2px', borderRadius: '20px', border: '1px solid', backgroundColor: '#FF5722', color: 'white', width: '300px' }} className="well">
                                        <h5>Today's Approved Shlok</h5>
                                        <p>{this.state.TodayApprovedShlokCount}</p>
                                    </div>
                                </div>  
                               


                            </div>



                        </div>



                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DashboardMini;