import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";

const date = new Date();
class AddContestent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedState: '',
            selectedDistrict: '',
            selectedAge: '',
            contestentID: props.contestentID || '',
            pathname: window.location.pathname,
            target_native_id: '',
            male:false,
            female:false,
            verifier: Math.floor(Math.random() * 10000),
            today : date.getFullYear() + '-' + '0' +(date.getMonth() + 1) + '-' + date.getDate()
        };

    }

    async componentDidMount() {

        let isLoginUser = getFromSession('user')
		this.setState({
			isLoginUser: isLoginUser.response
        })
        
        let split_pathname = this.state.pathname.split('/')
        let target_native_id = split_pathname[1]
        this.setState({
            target_native_id: target_native_id
        })
        
        if (this.state.contestentID) {

            let val_obj = {
                url: `Contestent/GetContestentByRegNo?RegNum=${this.state.contestentID}`,
                data: '',
                request_type: 'GET'
            }
           
            let response = await requestServerData(val_obj, 'admin')
            let obj = response.data.data.result
// alert("abc")
            let mydob=this.formatDate(new Date(obj.Dob));
            //  let sage= ;
          
        
         
          if(obj.Gender==='male')
          
          this.setState({male:true,female:false});
                else
            this.setState({male:false,female:true});
                // alert(g)
            console.log("response obj", obj)
            this.setState({
                editId: obj.ContestantId, name: obj.ContestantName, mobile: obj.MobileNo,
                 selectedState: obj.State, selectedDistrict: obj.District, 
                 tehsil: obj.Tehsil, nameUpiId: obj.NameInUpi, upiID: obj.UpiId,
                  donate: obj.OptOutRemuneration,  UpiStatus: obj.UpiStatus ,
                     email:obj.Email ,image : obj.Image,
                   selectedAge: obj.Age,
                dob:mydob,
                gender:obj.Gender
            })
            
        }
    }

    render() {

        return (

            <Card style={{ padding: 20 }}>
                <div>
                    <ToastContainer
                        position="top-center"
                    />
                    <FormGroup>
                        <Label >Name</Label>
                        <Input type="name" name="name" id="name" placeholder="Your Full Name *" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                    <FormGroup>
                        <Label >Email</Label>
                        <Input type="email" name="email" id="email" placeholder="Your Email Id *" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <FormGroup style={{ maxWidth: '50%' }}>
                            <Label >Date of birth</Label>
                            <Input type="date" name="dob" id="dob" value={this.state.dob} max={this.state.today} onChange={(e) => this.handleChange(e)} />
                        </FormGroup>

                        <Label >Age : {this.state.selectedAge}</Label>

                    </div>

                    <FormGroup style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Label >Gender</Label>
                        <div>
                            <Input type="radio" name="gender" id="male" onChange={(e) => this.handleChange(e)} checked={this.state.male || this.state.gender == "male"} />
                            <Label >Male</Label>
                        </div>
                        <div>
                            <Input type="radio" name="gender" id="female" onChange={(e) => this.handleChange(e)} checked={this.state.female || this.state.gender == "female" } />
                            <Label >Female</Label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label >Mobile Number</Label>
                        <Input type="mobile" name="mobile" id="mobile" placeholder="Your mobile Number *" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                    <FormGroup>
                        <Label >Upi ID</Label>
                        <Input type="upiID" name="upiID" id="upiID" placeholder="Your Upi ID *" value={this.state.upiID} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                    <FormGroup>
                        <Label >Name of Upi ID</Label>
                        <Input type="nameUpiId" name="nameUpiId" id="nameUpiId" placeholder="Your Upi ID Name *" value={this.state.nameUpiId} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>
                    {
                        this.state.target_native_id === 'contestent-list' &&

                        <div style={{ display: 'flex', alignItems: "center", width: '100%', margin: '15px 0px' }}>
                            <Label style={{ marginRight: 20 }}>Upi Status</Label>

                            <Dropdown isOpen={this.state.isUPI} toggle={() => this.handleClickDropDwon('upi_status')}>
                                <DropdownToggle caret>
                                    {this.state.UpiStatus === "Pending" ? "Pending" : this.state.UpiStatus === "Verified" ? "Verified" : 'Select UPI Status'}{'  '}
                                </DropdownToggle>
                                <DropdownMenu style={{ height: 200, overflow: 'scroll' }}>
                                    {
                                        NativeDb['UpiStatus'].map((item, index) => {
                                            return (
                                                <DropdownItem key={index} onClick={() => { this.setState({ UpiStatus: item }) }}>{item}</DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    }

                    {
                        this.state.target_native_id === 'contestent-list' ?
                            null
                            :
                            <React.Fragment>
                                <div style={{ display: 'flex', width: '100%', margin: '15px 0px' }}>

                                    <div style={{ maxWidth: '50%' }}>

                                        <Dropdown isOpen={this.state.isState} toggle={() => this.handleClickDropDwon('state')} >
                                            <DropdownToggle caret style={{padding:10}}>
                                                {this.state.selectedState.StateName ? this.state.selectedState.StateName : "Select state"}
                                            </DropdownToggle>
                                            <DropdownMenu style={{ height: 200, overflow: 'scroll' }}>
                                                {
                                                    NativeDb['states'].map((state, index) => {
                                                        return (
                                                            <DropdownItem key={index} onClick={() => { this.setState({ selectedState: state }) }}>{state.StateName}</DropdownItem>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>

                                    <div style={{ maxWidth: '50%' }}>

                                        <Dropdown isOpen={this.state.isDistrict} toggle={() => this.handleClickDropDwon('district')} >
                                            <DropdownToggle caret style={{padding:10}}>
                                                {this.state.selectedDistrict.DistrictName ? this.state.selectedDistrict.DistrictName : "Select District"}
                                            </DropdownToggle>
                                            <DropdownMenu style={{ height: 200, overflow: 'scroll' }}>
                                                {
                                                    NativeDb['cities'].map((city, index) => {
                                                        if (city.StateId === this.state.selectedState.StateId) {
                                                            return (
                                                                <DropdownItem key={index} onClick={() => { this.setState({ selectedDistrict: city }) }}>{city.DistrictName}</DropdownItem>
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <FormGroup>
                                    <Label for="exampleEmail">Tehsil</Label>
                                    <Input type="tehsil" name="tehsil" id="tehsil" placeholder="Your tehsil *" value={this.state.tehsil} onChange={(e) => this.handleChange(e)} />
                                </FormGroup>
                            </React.Fragment>
                    }

                    <FormGroup>
                        <Label >Password</Label>
                        <Input type="password" name="password" id="password" placeholder="Your password*" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    <FormGroup>
                        <Label >Confirm Password</Label>
                        <Input type="password" name="cpassword" id="cpassword" placeholder="Cofirm your password*" value={this.state.cpassword} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    <FormGroup>
                        <Label >Verify Your Self</Label>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p className={'verify'}>{this.state.verifier}</p>
                        </div>
                        <Input type="text" name="verify" id="verify" placeholder="enter the verification number" value={this.state.verify} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    {
                        this.state.target_native_id === 'add-contestent' || this.state.target_native_id === 'contestent-list' ?
                            null
                            :
                            < FormGroup check style={{display:'flex', alignItems:'center'}}>
                                <Label check >
                                    <Input name={'donate'} type="checkbox" value={this.state.donate} onChange={(e) => this.handleCheckBox(e)} />
                                    <a >I Agree With This </a>
                                </Label>
                                <a style={{color:'#4285f4'}} onClick={() => { this.setState({ TermsAndCondition: true }) }}> Terms & Conditions</a>
                            </FormGroup>
                    }

                    {
                        !this.state.target_native_id === 'contestent-list' ?
                            null
                            :
                            <React.Fragment>
                                {
                                    this.state.loading ?
                                        this.state.target_native_id === 'contestent-list' ?
                                            <Button color={'primary'} style={{ marginTop: 15, width: '100%' }} >Edit Contestent</Button>
                                            :
                                            this.state.target_native_id === 'add-contestent' ?
                                                <Button style={{ marginTop: 15, width: '100%' }} >Add New Contestent</Button>
                                                :
                                                <Button style={{ marginTop: 15, width: '100%' }} >Register</Button>
                                        :
                                        this.state.target_native_id === 'contestent-list' ?
                                            <Button color={'primary'} style={{ marginTop: 15, width: '100%' }} >Edit Contestent</Button>
                                            :
                                            this.state.target_native_id === 'add-contestent' ?
                                                <Button style={{ marginTop: 15, width: '100%' }} onClick={() => this.handleRegister()}>Add New Contestent</Button>
                                                :
                                                <Button style={{ marginTop: 15, width: '100%' }} onClick={() => this.handleRegister()}>Register</Button>
                                }
                            </React.Fragment>
                    }
                </div>

                {
                    this.state.loading &&
                    <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                }
                {
                    this.state.modalShow &&
                    <Modal isOpen={this.state.modalShow} toggle={this.handleCloseModal}>
                        <ModalBody>
                            {this.state.message}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.handleCloseModal}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                }
                {
                    this.state.TermsAndCondition &&
                    <Modal isOpen={this.state.TermsAndCondition} toggle={this.handleCloseTermsAndCondion}>
                        <ModalBody>
                            <Terms />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" onClick={this.handleCloseTermsAndCondion}>Accept</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Card >

        );
    }

    handleChange = (event) => {

        if (event.target.name === 'gender') {

            this.setState({
                [event.target.name]: event.target.id
            })
        } else if (event.target.name === 'dob') {          
          
           
            var today = new Date();
            let selectedYearArray = event.target.value.split('-')
            let selectedYear = selectedYearArray[0]
            let age = today.getFullYear() - JSON.parse(selectedYear)
            this.setState({
                selectedAge: age, [event.target.name]: event.target.value
            })
        } else if(event.target.name === 'mobile'){

            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }
        } else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    handleCheckBox = (e) => {

        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    handleClickDropDwon = (key, data) => {

        switch (key) {
            case 'state':

                this.setState({
                    isState: !this.state.isState
                })
                break;

            case 'district':

                this.setState({
                    isDistrict: !this.state.isDistrict
                })
                break;

            case 'upi_status':

                this.setState({
                    isUPI: !this.state.isUPI
                })
                break;

            default:
                break;
        }
    }


    handleCloseModal = () => {

        this.setState({
            modalShow: false, name: '', mobile: '', selectedState: '', selectedDistrict: '', tehsil: '', nameUpiId: '', upiID: '', donate: '', selectedAge: ''
        })
    }

    handleCloseTermsAndCondion = () => {

        this.setState({
            TermsAndCondition: false
        })
    }
//cst
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

//     editContestent =  () =>{

//         const { editId, email, name, mobile, selectedState, selectedDistrict, tehsil, nameUpiId, upiID, donate, selectedAge, UpiStatus, gender, dob, password, cpassword, verify, verifier } = this.state;
// console.log(this.state);
// let data_url = ''
//                 let RegistrationType = ''
//                 let requestType = ''
//                 let createdby = ''
//                 let OptOutRemuneration = donate


//                     data_url = 'Contestent/UpdateContestent'
//                     // RegistrationType = 'admin'
//                     createdby = 2

//                     requestType = 'POST'
//                     OptOutRemuneration = true
//                     let val_obj = {

//                         data: {
//                             "ContestantId": editId,
//                             "ContestantName": name,
//                             "MobileNo": mobile,
//                             "State": selectedState.StateCode,
//                             "District": selectedDistrict.DistrictName,
//                             "Tehsil": tehsil,
//                             "Age": selectedAge,
//                             "UpiId": upiID,
//                             "NameInUpi": nameUpiId,
//                             "OptOutRemuneration": OptOutRemuneration,
//                             // "RegistrationType": RegistrationType,
//                             "UpiStatus": UpiStatus,                       
//                             "Dob": dob,
//                             "Email":email,
//                             "Gender": gender,
//                             "Image": '',
//                             "u":{
    
//                                 "CreatedBy": createdby,
//                                 "Password": password
//                             }
//                         },
//                         request_type: requestType,
//                         url: data_url
//                     }
//                     console.log('val_obj', val_obj)
    
// }  
// 

    handleRegister = async () => {

        const { editId, email, name, mobile, selectedState, selectedDistrict, tehsil, nameUpiId, upiID, donate, selectedAge, UpiStatus, gender, dob, password, cpassword, verify, verifier } = this.state;
console.log(this.state);
        if (name && mobile && email && selectedState && selectedDistrict && tehsil && nameUpiId && upiID && selectedAge && gender && password && dob && cpassword && verify) {

            let error = ''
            error = emailVerifier(email)
            error = upiIdCheck(upiID)

            if (password !== cpassword)
             {
                error = 'password does not match'
            }

            if (verify.toString() !== verifier.toString()) {
                error = 'you have to verify first !'
            }


            this.setState({
                loading: true
            })

            if (error) {

                toast.error(error)
                this.setState({
                    loading: false
                })
            } else {

                let data_url = ''
                let RegistrationType = ''
                let requestType = ''
                let createdby = ''
                let OptOutRemuneration = donate
                if (this.state.target_native_id === 'contestent-list') {

                    data_url = 'Contestent/UpdateContestent'
                    // RegistrationType = 'admin'
                    createdby = 2

                    requestType = 'POST'
                    OptOutRemuneration = true
                } else {

                    if (window.location.pathname === '/add-contestent') {

                        // RegistrationType = 'admin'
                        createdby = 2
                        OptOutRemuneration = true
                    } else {

                        // RegistrationType = 'user'
                        createdby = 3
                    }
                    data_url = 'Contestent/InsertContestent'
                    requestType = 'POST'

                }
                let val_obj = {

                    data: {
                        "ContestantId": editId,
                        "ContestantName": name,
                        "MobileNo": mobile,
                        "State": selectedState.StateCode,
                        "District": selectedDistrict.DistrictName,
                        "Tehsil": tehsil,
                        "Age": selectedAge,
                        "UpiId": upiID,
                        "NameInUpi": nameUpiId,
                        "OptOutRemuneration": OptOutRemuneration,
                        // "RegistrationType": RegistrationType,
                        "UpiStatus": UpiStatus,                       
                        "Dob": dob,
                        "Email":email,
                        "Gender": gender,
                        "Image": '',
                        "u":{

                            "CreatedBy": createdby,
                            "Password": password
                        }
                    },
                    request_type: requestType,
                    url: data_url
                }
                console.log('val_obj', val_obj)

                let response = ''

                if (RegistrationType === 'user') {

                    if (donate) {

                        response = await requestServerData(val_obj, 'user')
                       
                    } else {

                        toast.error('please select terms & conditions .!')
                    }

                } else {

                    response = await requestServerData(val_obj, 'admin')
                }

                console.log('val_obj', response)

                if (response.response) {

                    if(response.data && response.data.data.ResponceMessage === 'Insert Sucessfully'){

                        let current_path = window.location.pathname;
                        if (current_path === '/register-user') {
                            
                            if(this.state.isLoginUser){

                                this.props.history.push('/user-dashboard',{data: response.data.data.result})
                            }else{
                                
                                this.props.history.push('/user-login',{data: response.data.data.result})
                            }
                        }
                        
                        if (current_path === '/contestent-list') {
                            
                            this.props.handleClose('close_modal')
                        } if (current_path === '/add-contestent') {
                            
                            this.props.history.push('/contestent-list')
                        }
                    }else{

                        toast.error(response.data.data.ResponceMessage)
                    }
                    this.setState({
                        loading: false
                    })

                }
                 else {

                    toast.error(response.data.data.ResponceMessage)
                }
            }
        } else {

            toast.error('please fill all the value . . .!')
        }
        this.setState({
            loading: false
        })
    }
}



export default withRouter(AddContestent);