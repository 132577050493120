import React, { Component } from "react";
import { Card, Button, Input, Spinner, Label, Modal, ModalBody, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { requestServerData } from '../Utils/Config'
import { withRouter } from "react-router-dom";
import Terms from '../Common/Terms'
import { ToastContainer, toast } from 'react-toastify';
import { emailVerifier, AllowOnlyNumber,upiIdCheck } from '../Validation/Validation'
import { getFromSession } from '../Utils/SessionStorage'
import { setGlobalCssModule } from "reactstrap/lib/utils";
const date = new Date();
class EditContestent extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
            selectedAge: '',
            contestentID: props.contestentID || '',
            pathname: window.location.pathname,
            target_native_id: '',
            male:false,
            female:false,                      
            today : date.getFullYear() + '-' + '0' +(date.getMonth() + 1) + '-' + date.getDate()
        };

    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    async componentDidMount() {

        let isLoginUser = getFromSession('user')
		this.setState({
			isLoginUser: isLoginUser.response
        })
        
                
        if (this.state.contestentID) {

            let val_obj = {
                url: `Contestent/GetContestentByRegNo?RegNum=${this.state.contestentID}`,
                data: '',
                request_type: 'GET'
            }
           
            let response = await requestServerData(val_obj, 'admin')
            let obj = response.data.data.result

            let mydob=this.formatDate(new Date(obj.Dob));
         
          if(obj.Gender==='male')
          
          this.setState({male:true,female:false});
                else
            this.setState({male:false,female:true});


           

            console.log("response obj", obj)
            this.setState({
                editId: obj.RegistrationCode, name: obj.ContestantName, mobile: obj.MobileNo,
                nameUpiId: obj.NameInUpi, upiID: obj.UpiId,                  
                   email:obj.Email ,image : obj.Image,
                   selectedAge: obj.Age,
                   dob:mydob,
                   gender:obj.Gender
            })
            
        }
    }
    handleRegister = async () =>
     {

        const { editId, email, name, mobile,nameUpiId, upiID,selectedAge, gender, dob} = this.state;

        if (name && mobile  && nameUpiId && upiID && selectedAge  )
         {

                    let error = ''
                    error = emailVerifier(email)
                    error = upiIdCheck(upiID)   
                    this.setState({
                        loading: true
                    })
            if (error) {
                toast.error(error)
                this.setState({
                    loading: false
                })
                } else {

                let data_url = ''               
                let requestType = ''
                let createdby = ''
             data_url = 'Contestent/UpdateContestent'
                  
                    createdby = 1
                    requestType = 'POST'  
                let val_obj = {

                    data: {
                        "RegistrationCode": editId,
                        "ContestantName": name,
                        "MobileNo": mobile,
                        "Age": selectedAge,
                        "UpiId": upiID,
                        "NameInUpi": nameUpiId, 
                                            
                        "Dob": dob,
                        "Email":email,
                        "Gender": gender, 
                        "CreatedBy": createdby,                        
                    },
                    request_type: requestType,
                    url: data_url
                }
                console.log('val_obj', val_obj)     
                let response = ''       

                        response = await requestServerData(val_obj, 'admin')  
                        toast.success('Record Updated successfully!')
                    }  
                    this.setState({
                        loading: false
                    })  
                       
         
        } 
        

        
    }
    render(){
        return(
            <Card style={{ padding: 20 }}>
            <div>
                <ToastContainer
                    position="top-center"
                />
                <FormGroup>
                    <Label >Name</Label>
                    <Input type="name" name="name" id="name" placeholder="Your Full Name *" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Email</Label>
                    <Input type="email" name="email" id="email" placeholder="Your Email Id *" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                </FormGroup>

                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <FormGroup style={{ maxWidth: '50%' }}>
                        <Label >Date of birth</Label>
                        <Input type="date" name="dob" id="dob" value={this.state.dob} max={this.state.today} onChange={(e) => this.handleChange(e)} />
                    </FormGroup>

                    <Label >Age : {this.state.selectedAge}</Label>

                </div>

                <FormGroup style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Label >Gender</Label>
                    <div>
                        <Input type="radio" name="gender" id="male" onChange={(e) => this.handleChange(e)} checked={this.state.male || this.state.gender == "male"} />
                        <Label >Male</Label>
                    </div>
                    <div>
                        <Input type="radio" name="gender" id="female" onChange={(e) => this.handleChange(e)} checked={this.state.female || this.state.gender == "female" } />
                        <Label >Female</Label>
                    </div>
                </FormGroup>


                <FormGroup>
                    <Label >Mobile Number</Label>
                    <Input type="mobile" name="mobile" id="mobile" placeholder="Your mobile Number *" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Upi ID</Label>
                    <Input type="upiID" name="upiID" id="upiID" placeholder="Your Upi ID *" value={this.state.upiID} onChange={(e) => this.handleChange(e)} />
                </FormGroup>
                <FormGroup>
                    <Label >Name of Upi ID</Label>
                    <Input type="nameUpiId" name="nameUpiId" id="nameUpiId" placeholder="Your Upi ID Name *" value={this.state.nameUpiId} onChange={(e) => this.handleChange(e)} />
                </FormGroup>              
               
                
                <FormGroup>
                <Button style={{ marginTop: 15, width: '100%' }} onClick={() => this.handleRegister()}>Update Contestent</Button>
                </FormGroup>
                
               
            </div>
            {/* {
                this.state.loading &&
                <div style={{ margin: '15px 0px', display: 'flex', justifyContent: 'center' }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
            } */}
            {
                this.state.modalShow &&
                <Modal isOpen={this.state.modalShow} toggle={this.handleCloseModal}>
                    <ModalBody>
                        {this.state.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleCloseModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
           
        </Card >
        )

        
    }
    handleCloseModal = () => {

        this.setState({
            modalShow: false, name: '', mobile: '', nameUpiId: '', upiID: '',selectedAge: ''
        })
    }

    handleChange = (event) => {

        
        if (event.target.name === 'gender') {

            this.setState({
                [event.target.name]: event.target.id
            })
        }
        else if (event.target.name === 'dob') {          
          
           
            var today = new Date();
            let selectedYearArray = event.target.value.split('-')
            let selectedYear = selectedYearArray[0]
            let age = today.getFullYear() - JSON.parse(selectedYear)
            this.setState({
                selectedAge: age, [event.target.name]: event.target.value
            })
        } else if(event.target.name === 'mobile'){

            if(AllowOnlyNumber(event.target.value)){
                
                this.setState({
                    [event.target.name]: event.target.value
                })
            }else{

                toast.error('only number is allow')
            }
        } else {

            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }
    

    

}
export default withRouter(EditContestent);