import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import { getFromSession } from '../Utils/SessionStorage'
import 'react-toastify/dist/ReactToastify.css';

const date = new Date();
class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: "",
            start: date.getFullYear() + '-' + '0' + (date.getMonth() + 1) + '-' + (date.getDate()),
            end: date.getFullYear() + '-' + '0' + (date.getMonth() + 1) + '-' + (date.getDate()),
            user: getFromSession('user'),
            isDisable:false
        };
    }

    componentDidMount() {

        this.getWalletData()
        this.getKycForUser()
    }

    render() {

        // if (this.state.loading) {
        //     return (
        //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
        //             <div >
        //                 <Spinner style={{ width: '3rem', height: '3rem' }} />
        //             </div>
        //         </div>
        //     )
        // }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer position="top-center" />
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Vedic Earning</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20 }}>

                        <p color="primary" >Total Wallet Amount : <span class="badge badge-primary"> {this.state.totalContestnentPayout} </span> </p>

                        <div>
                            <Button disabled={this.state.isDisable} color={'primary'} onClick={() => this.requestPayments(this.state.kycStatus ? 'payment' : 'updateKyc')}>{this.state.kycStatus ?'Request Payment' : 'Please Update Your Kyc for Payment'}</Button>
                        </div>

                    </div>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    {/* <p color = "primary" >Total Shlok : {this.state.totalContestnentPayout} </p>
                                <p color = "primary"  >Total Amount : {this.state.totalContestnentPayout} </p> */}
                                    <div className="table-responsive">
                                        <MDBDataTable
                                            id={'pendingPayouts'}
                                            paging={false}
                                            striped
                                            bordered
                                            hover
                                            data={this.state.walletData}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    getKycForUser = async () => {

        this.setState({
            loading: true
        })

        let val_obj = {
            url: `Contestent/GetKycDataByRegNo?RegNum=${this.state.user.data.uid}`,
            data: '',
            request_type: 'GET'
        }

        // console.log('kyc update', val_obj)
        // debugger
        let response = await requestServerData(val_obj, 'user')
        console.log('kyc update', response.data.data.result)
        if (response.response) {

            let data = response.data.data.result && response.data.data.result[0] || undefined
            if (data) {
                this.setState({
                    kycStatus: data.KycStatus
                })
            }
        } else {

            alert('try again . . .')
        }
        this.setState({
            loading: false
        })
    }

    getWalletData = async () => {

        this.setState({
            loading: true
        })

        let data =
        {
            "RegCode": this.state.user.data.uid,
            "ClaimStatus": "unclaimed",
        }
        let val_obj = {
            url: `ApprovedShloks/WalletHistory`,
            data: data,
            request_type: 'POST'
        }

        //console.log('wallet data val obj', val_obj)
        let response = await requestServerData(val_obj, 'user')
        // console.log('wallet data', response.data.data.result)
        // debugger
        if (response.response) {
            if (response.data.data.result && response.data.data.result.length > 0) {
                const data = {
                    columns: [
                        { label: 'Date', field: 'DateOnSubmitiononTelegram', },
                        { label: 'Total Shlok ', field: 'ShlokCount', },
                        { label: 'Amount', field: 'TotalAmount', },
                        { label: ' Status', field: 'ClaimStatus', },
                    ],
                    rows: this.assemblePosts(response.data.data),
                }

                let totalAmount = 0
                response.data.data.result && response.data.data.result.map((item, index) => {

                    if (item.TotalAmount > 0) {
                        totalAmount = totalAmount + item.TotalAmount
                    }
                })
                this.setState({ claimWalletData: response.data.data.result, walletData: data, totalContestnentPayout: totalAmount })
            } else {
                this.setState({ walletData: [] })
            }
        } else {

            alert('try again . . .')
        }
        this.setState({ loading: false })
    }

    assemblePosts = (ContestnentData) => {

        let posts = ContestnentData && ContestnentData.result && ContestnentData.result.map((post) => {
            return (
                {
                    DateOnSubmitiononTelegram: post.DateOnSubmitiononTelegram.split('T')[0],
                    ShlokCount: post.ShlokCount,
                    TotalAmount: post.TotalAmount,
                    ClaimStatus: post.ClaimStatus,
                }
            )
        });
        return posts || '';
    }

    requestPayments = async (key) => {
       
        if (key === 'updateKyc') {

            this.props.history.push('/user-kyc-update')
        } else {

           
                if(this.state.totalContestnentPayout>500)
                {
                   
                    if (this.state.claimWalletData) {

                             this.setState({
                             loading: true,
                            isDisable:true
                                                    })

                         let claimWalletData = this.state.claimWalletData

                        claimWalletData && claimWalletData.map((item, index) => {

                             Object.assign(item, { RegCode: this.state.user.data.uid })
                                         })

                             let data = claimWalletData
                             let val_obj = {
                               url: `ApprovedShloks/ClaimWallet`,
                           // url:``,
                         data: data,
                         request_type: 'POST'
                                              }

                      // console.log('requst payment', val_obj)
                     let response = await requestServerData(val_obj, 'user')
                        // console.log('requst payment', response.data.data.result)

                             if (response.response) {
                              this.getWalletData();

                                 this.setState({
                             claimWalletData: '',totalContestnentPayout:0,
                                isDisable:false
                                     })
                   
                    alert("Payment Request Process Successfully")

                   // toast.success(response.data.data.ResponceMessage)
                } else {
                    this.setState({
                        isDisable:false
                    })
                    toast.error(response.data.data.ResponceMessage)
                        }
                    }
                    
                 
            }
            else
            {
               
             toast.error("Payment request is acceptable only above 500 Rs")  
                   
            }
           
          

        }

        this.setState({
            loading: true
        })
    }

}

export default withRouter(Wallet);