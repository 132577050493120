import React from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import { withRouter } from "react-router-dom";

const chapterMapper = {
  अक्षरब्रह्मयोग: 8,
  अर्जुनविषादयोग: 1,
  आत्मसंयमयोग: 6,
  कर्मयोग: 3,
  कर्मसंन्यासयोग: 5,
  क्षेत्रक्षेत्रज्ञविभागयोग: 13,
  गुणत्रयविभागयोग: 14,
  ज्ञानकर्मसंन्यासयोग: 4,
  ज्ञानविज्ञानयोग: 7,
  "दैवासुरसम्पद्विभाग  योग": 16,
  पुरुषोत्तमयोग: 15,
  भक्तियोग: 12,
  मोक्षसंन्यासयोग: 18,
  राजविद्याराजगुह्ययोग: 9,
  विभूतियोग: 10,
  विश्वरूपदर्शनयोग: 11,
  श्रद्धात्रयविभागयोग: 17,
  सांख्ययोग: 2
};

class Certificate extends React.Component {
  state = {
    loading: true
  };

  myRef = React.createRef();

  componentWillMount() {
    let { chapter = "" } = this.props.match.params || {};
    if(chapter) chapter = chapter.trim()
    let chapter_number = chapterMapper[chapter];
    let source = null;
    try {
      source = require(`../Assets/Certificates/${chapter_number}.jpg`);
    } catch (e) {
      source = null;
    }

    this.image = source ? (
      <img
        ref={this.myRef}
        src={source}
        style={{
          width: "100vw"
        }}
        onLoad={event => this.handleImageLoaded(event)}
        onError={event => this.handleImageError(event)}
        alt="Certificate"
      />
    ) : null;
  }

  handleResize = () => {
    this.setState({});
  };

  handleImageLoaded = () => {
    this.setState({ loading: false });
  };
  
  handleImageError = () => {
    this.setState({ loading: true });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUnMount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { name = "" } = this.props.match.params || {};

    /*
      this.image in both of spinner and  
     */
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh"
          }}
        >
          {this.image}
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "relative",
            width: "100vw",
            minHeight: "100vh",
            backgroundColor: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <input
            style={{
              position: "fixed",
              top: "2%",
              right: "2%",
              padding: "10px 20px",
              borderRadius: "10px",
              color: "white",
              backgroundColor: "steelBlue"
            }}
            className="no-print"
            type="button"
            value="Print"
            onClick={e => window.print()}
          />
          {this.image}
          <p
            style={{
              position: "absolute",
              top: "50.8%",
              left: "45%",
              transform: "translate(-50%, -50%)",
              fontSize: "2vw"
            }}
          >
            {name}
          </p>
        </div>
      );
    }
  }
}

export default withRouter(Certificate);
