import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
// import SideBar from './SideBar';
import { Card, Button, Input, Spinner, Label, Modal,DropdownButton, ModalBody,ModalHeader, ModalFooter, FormGroup, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { ToastContainer, toast } from 'react-toastify';
import { AllowOnlyNumber } from '../Validation/Validation'
import NativeDb from '../Utils/nativeDb.json'

class AddUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            UserName: '', 
            Password:'',
            Status:''           
        }
    }


handleSubmit = async () =>
{
    const { username,status} = this.state; 
  
    let val_obj = {
        data: {
                  "UserName": username,
                  "Status":status,
                 
                
              },
              request_type: "POST",
               url: 'User/InsertUser'
               
      }

        console.log('val_obj', val_obj)     
           let response = ''
           response = await requestServerData(val_obj, 'admin')  
           
           if (response.response)
            { 
                toast.success('User Added Sucessfully')
                 this.setState(
                     {           
                 username:'',
                 status:'' 
                    }
                    );
             }
             else {
           
                toast.error('Please try again')
            }
            this.setState({
                loading: false
            })
    
    }     
              
   

   


 

    render() 
    {

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
            <div style={{ paddingTop: '50px', margin: 'auto', width: '500px' }}>
                <Card style={{ padding: 20 }}>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                    <h2>Add User</h2>
                    <div class="form-group">
                    <label class>User Type Id* </label>
                    <select value={this.state.usertypeid}>
                        <option value="superadmin"></option>
                        <option value="admin"></option>
                        <option value="admin"></option>

                       
                    </select>
                    </div>
                    <div class="form-group">
                        <label class>User Name*</label>
                        <input name="username" id="username" placeholder="User Type Name" class="form-control" type="text"  value={this.state.username}/>
                    </div>
                    <div class="form-group">
                        <label class>Password</label>
                        <input name="password" id="password" placeholder="Enter Password" class="form-control" type="password"  value={this.state.password} />
                    </div>
                    
                    {/* <div class="form-group">
                        <label class>Status*</label>
                        <input name="status" id="status" placeholder="IsActive" class="form-control" type="text" value={this.state.status}
                            />
                    </div> */}
                    <div class="form-group">
                    <label class>Status* </label>
                    <select value={this.state.status}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                       
                    </select>
                    </div>

                    <button type="submit" class="btn btn-secondary" style={{ marginTop: "12px", width: "100%", backgroundColor: "	#9932CC" }} onClick={() => this.handleSubmit()}>Submit User </button>

               
                </Card>
            </div>
        </div>
    )

}
}
export default withRouter(AddUser);