import React, { Component } from "react";
import Home from "../Common/Home";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AdminLogin from "../Admin/AdminLogin";
import AdminRoutes from "./AdminRoute";
import PrivateRoute, { userRoleVisibility } from "./PrivateRoute";
import Terms from "../Common/Terms";
import Aboutus from "../Common/Aboutus";
import Donationform from "../Common/Donationform";
import UserDashboardMini from "../UsersComponents/UserDashboardMini";
import HomePage from "../Common/HomePage";
import Certificate from "../UsersComponents/Certificates";
import PageUnderConstruction from "../Common/PageUnderConstruction";
let hashHistory = Router.hashHistory;

class RoutesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  render() {
    return (
      <Router history={hashHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/user-login" component={Home} />
        <Route exact path="/register-user" component={Home} />
        <Route exact path="/donation-form" component={Home} />

        <Route exact path="/user-dashboard" component={Home} />
        <Route exact path="/forget-password" component={Home} />
        <Route exact path="/user-attempted-shlok" component={Home} />
        <Route exact path="/user-non-attempted-shlok" component={Home} />
        {/* <Route exact path="/donation-form" component={Home} /> */}
        <Route exact path="/user-profile" component={Home} />
        <PrivateRoute
          exact
          path="/user-payment"
          component={Home}
          visible={userRoleVisibility}
        />
        <Route exact path="/user-wallet" component={Home} />
        <Route exact path="/user-request" component={Home} />
        <Route exact path="/user-rewards" component={Home} />
        <Route exact path="/user-kyc-update" component={Home} />
        <Route exact path="/user-dashboard-mini" component={Home} />
        <Route
          exact
          path="/user-certificate/:name/:chapter"
          component={Certificate}
        />
        <Route exact path="/page-under-construction" component={Home} />

        <Route exact path="/profile-picture" component={Home} />

        <Route exact path="/terms" component={Home} />
        <Route exact path="/faq" component={Home} />
        <Route exact path="/about-us" component={Home} />
        <Route exact path="/contactus" component={Home} />

        <Route exact path="/login" component={AdminLogin} />
        <Route exact path="/contestent-list" component={AdminRoutes} />
        <Route exact path="/dashbord" component={AdminRoutes} />
        <Route exact path="/add-contestent" component={AdminRoutes} />
        {/* <Route exact path="/edit-contestent" component={AdminRoutes} /> */}
        <Route
          exact
          path="/edit-contestent/:contestentID"
          component={AdminRoutes}
        />
        <Route
          exact
          path="/approved-slok/:contestentID"
          component={AdminRoutes}
        />
        <Route exact path="/view-approved-slok/" component={AdminRoutes} />
        <Route exact path="/new-approved-shlok/" component={AdminRoutes} />
        <Route exact path="/pending-payout/" component={AdminRoutes} />
        <Route exact path="/contestent-payout" component={AdminRoutes} />
        <Route exact path="/contestent-update-payout" component={AdminRoutes} />
        <Route exact path="/donate" component={AdminRoutes} />
        <Route exact path="/donation" component={AdminRoutes} />
        <Route exact path="/contactus-entry" component={AdminRoutes} />
        <Route exact path="/add-user-type" component={AdminRoutes} />
        <Route exact path="/add-user-type-form" component={AdminRoutes} />
        <Route exact path="/add-user" component={AdminRoutes} />
        <Route exact path="/wallet" component={AdminRoutes} />
      </Router>
    );
  }
}

export default RoutesPage;
