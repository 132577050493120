import React, { Component } from "react";
import { Badge, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import AddContestent from '../Admin/AddContestent'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import DashboardMini from '../Admin/DashboardMini'



class DonationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

        this.handleGetDataForTable()
    }

    render() {

        if (this.state.loading) {

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer
                        position="top-center"
                    />
                    <Row>
                        {/* <DashboardMini/> */}

                    </Row>
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Donation List</h3>
                                    <hr />
                                    <Row className="align-items-center">
                                        {/* <h4 className="page-center">
                                            Total :
                                            {this.state.ContestnentDataCount}
                                        </h4> */}
                                        <Col className="d-flex justify-content-end">
                                            <Button className="align-self-right" color="info" onClick={() => this.handleClick('add')}>Add new </Button>
                                            {/* <Button className="align-self-right" color="info" >Add new </Button> */}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />

                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                <div className="table-responsive">
                                    <MDBDataTable
                                        id={'DonationList'}
                                        // paging={true}
                                        striped
                                        bordered
                                        entriesOptions={[10, 50, 100, 1000]}
                                        hover
                                        width={50}
                                        data={this.state.DonationData}
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }

    assemblePosts = (DonationData) => {

        let posts = DonationData && DonationData.result.map((post) => {

            // let date = post.CreatedDate && post.CreatedDate.split('T')[0]

            return (
                {
                    ReceiptNo: post.ReceiptNo,
                    DonorName: post.DonorName,
                    MobileNumber: post.MobileNumber,
                    Address: post.Address,
                    District: post.District,
                    State: post.State,
                    Pincode: post.Pincode,
                    DonationAmount: post.DonationAmount,
                    // TotalCount : post.TotalCount,
                    // UpiStatus : post.UpiStatus === 'Verified' ? <Button color="success  mr-2 " onClick={() => this.changestatus(post.RegistrationCode)} 4444444444

                    // NameInUpi: post.NameInUpi,
                    // OptOutRemuneration: <Button className="btn-sm" color={post.OptOutRemuneration === true ? 'success' : 'danger '} > {post.OptOutRemuneration === true ? "Need Remenaration" : 'No Remenaration'}  </Button>,
                    // Action: <div className="action-icons">
                    // <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)} >Edit</Button>
                    // </div>,
                    //  APShlok: <Button  color="primary mr-2 " outline onClick={() => this.handleClick('approved', post)} >Approve<Badge style={{  fontWeight: 'bold', justifyContent: 'center' }} color="secondary">{post.TotalCount}</Badge></Button>

                }

            )

        });

        return posts;
    }

    handleClick = (key, data) => {

        switch (key) {

            case 'add':

                this.props.history.push('/donate')
                break;
        }
    }


    handleGetDataForTable = async () => {

        this.setState({
            loading: true
        })
        let val_obj = {
            url: 'Donation/GetAll',
            data: '',
            request_type: 'GET'
        }
        let response = await requestServerData(val_obj, 'admin')

        console.log('donation list', response.data.data.result)
        if (response.response) {

            const data = {
                columns: [
                    { label: 'DonorName', field: 'DonorName', },
                    { label: 'MobileNumber', field: 'MobileNumber', },
                    { label: 'Address', field: 'Address', },
                    { label: 'District', field: 'District', },
                    { label: 'State', field: 'State', },
                    { label: 'Pincode', field: 'Pincode', },
                    { label: 'DonationAmount', field: 'DonationAmount', }],

                rows: this.assemblePosts(response.data.data),
            }
            this.setState({ DonationData: data, DonationDataCount: response.data.data.result.length })
            //      toast.success("Success !")

        } else {

            alert('server error')
            this.props.history.push('/')
        }
        this.setState({ loading: false })
    }



}


export default withRouter(DonationList);