import React from 'react'
import { requestServerData } from '../Utils/Config'
import { Card, CardTitle, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Spinner } from 'reactstrap';


class StateWiseData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statewisedata: []
        }
    }

    componentDidMount() {

        this.GetStateData()
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.renderStateData()
                }
            </React.Fragment>
        );
    }

    renderStateData = () => {

        return (
            <div style={{ padding: 20, background: 'rgb(0, 188, 212)', display: 'flow-root', border: '1px solid black' }}>
                <CardTitle style={{ margin: 0, padding: 0, color: '#111', fontWeight: 'bold', fontSize:20 }}>State Wise List</CardTitle>
                {
                    this.state.statewisedata.map((item, index) => {
                        return (
                            <Card style={{ padding: 15, display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '10%', margin: 8, float: 'left',borderBottom:'solid 25px rgb(255, 87, 34)'  }}>
                                <CardTitle style={{margin: 0, padding: 0, color: '#111', fontWeight: 'bold' }}>{item.State}</CardTitle>
                               <CardTitle style={{ margin: 0, padding: 0, display: 'flex', color: 'white', fontWeight: 'bold', justifyContent: 'center', marginBottom:'-40px',background: 'rgb(255, 87, 34)', width: '30%', }}>{item.Count}</CardTitle>
                            </Card>
                        )
                    })
                }
            </div>

        )
    }

    GetStateData = async () => {
        this.setState({ loading: true })
        let val_obj = {
            url: `Dashboards/GetStateData`,
            data: '',
            request_type: 'get'
        }
        let response = await requestServerData(val_obj, 'admin')
        if (response.response) {
            var datastate = response.data.data.result;
            console.log('state wise data', datastate)
            this.setState({ statewisedata: datastate, loading: false })
        }
    }

}

export default StateWiseData;