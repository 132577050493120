import React from 'react'
import { Progress ,  Jumbotron, Button} from 'reactstrap';
import { Card, CardTitle, Form, FormGroup, Label, Input, Modal, ModalBody, Spinner } from 'reactstrap';
import NativeDb from '../Utils/nativeDb.json'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { requestServerData } from '../Utils/Config'
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import PendingPayout from './PendingPayout';
import DashboardMini from '../Admin/DashboardMini';
import StateWiseData from './StateWiseData'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            Dashboarddata:[],
            ContestentCount:"",
            ApprovedShlokCount:"",
            ApprovedShlokAmount:'',
            TodayApprovedShlokCount:'',
            TodayApprovedShlokAmount:'',
            MonthlyApprovedShlokCount:'',
            MonthlyApprovedShlokAmount:'',
            YearlyApprovedShlokCount:'',
            YearlyApprovedShlokAmount:'',
            TodayShlokCounttobeApproved:'',
            ShlokCounttobeApprovedTillDate:'',
            statewisedata:[],
            statelabels:[],
            statedata:[]       
         
         }
        
    }  




    componentDidMount(preProps, preState) {
        // this.GetDashboard();
        this.GetAdminDashboardData();

    }

    
  
    //   GetDashboard = async () => {
    //     this.setState({ loading: true })
    //     let val_obj = {
    //         url: `Dashboards/GetDashboardData`,
    //         data: '',
    //         request_type: 'get'
    //     }
    //         let response = await requestServerData(val_obj, 'admin')
    //         if (response.response) {
    //             console.log("selectedChapters1 ",response )
    //             var getdata = response.data.data.result; 
    //             console.log("getdata ",getdata)
    //             this.setState({ showchaptertitle:false, loading: false ,
    //                  Dashboarddata: response.data.data.result,
    //                  ApprovedShlokAmount: getdata.ApprovedShlokAmount,
    //                  ApprovedShlokCount: getdata.ApprovedShlokCount,
    //                  ContestentCount: getdata.ContestentCount,
    //                  MonthlyApprovedShlokAmount: getdata.MonthlyApprovedShlokAmount,
    //                  MonthlyApprovedShlokCount: getdata.MonthlyApprovedShlokCount,
    //                  ShlokCounttobeApprovedTillDate: getdata.ShlokCounttobeApprovedTillDate,
    //                  TodayApprovedShlokCount: getdata.TodayApprovedShlokCount,
    //                    })
        

    //            console.log("ap",this.state.Dashboarddata)
    //             // console.log("selectedChapters", this.state.Dashboarddata )
            
    //         }else{
    //             alert('Try Again after some time . . .')
    //         }

    //     }
    
    GetAdminDashboardData = async () => {
        this.setState({ loading: true })
        let val_obj = {
            url: `Dashboards/GetAdminDashboardData`,
            data: '',
            request_type: 'get'
        }
        

        let response = await requestServerData(val_obj, 'admin')
        if (response.response) {
           
            var getdata = response.data.data.result;
        //   console.log(getdata);
       
            this.setState({
              
                Dashboarddata: response.data.data.result, 
                ContestentCount: getdata.ContestentCount, 
                ApprovedShlokCount: getdata.ApprovedShlokCount,               
                PaidAmount: getdata.PaidAmount,
                UnPaidAmount: getdata.UnPaidAmount,
                WalletAmount: getdata.WalletAmount,
                TodayApprovedShlokCount: getdata.TodayApprovedShlokCount,
              
                // PendingAmount: getdata.PendingAmount,
            })
        // alert(this.response)

            console.log("ap", this.state.Dashboarddata)        

        } else {
            alert('Try Again after some time . . .')
        }

    }
    render() {  
        return (
            <React.Fragment>
                 <DashboardMini/>
                 <StateWiseData />
                 {/* <PendingPayout/> */}
                </React.Fragment>    
        );
    }
    
}
 
export default Dashboard;