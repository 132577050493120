import React from 'react'
import { Card, CardTitle, Form, FormGroup, Label, Input, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import UserIcom from '../Assets/Images/userIcom'
import UserCross from '../Assets/Images/UserCross'
import UserRight from '../Assets/Images/UserRight'
import UserPayment from '../Assets/Images/UserPayment'
import UserWallet from '../Assets/Images/UserWallet'
import UerRequest from '../Assets/Images/UerRequest'
import UserRewards from '../Assets/Images/UserRewards'
import UserKyc from '../Assets/Images/UserKyc'
import Donate from '../Assets/Images/Donate'
import UserDashboardMini from './UserDashboardMini';



class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: props.location.state || undefined,
           

        }
    }

    componentDidMount() {
        if (this.state.successMessage) {

            toast.success(this.state.successMessage.data)
        }
    }



    render() {
        
        return (
            <React.Fragment>
            <UserDashboardMini/>
           
      
                <ToastContainer
                    position="top-center"
                />
                <div>
                    {
                        userDashboardMenu.map((item, index) => {
                            return (
                                <div key={index} className={'shadow'} style={{ background: '#fff', padding: 6, width: '100%', margin: 5, width: '30%', minHeight: 100, float: "left" }} onClick={()=>{this.handleClick(item)}} >
                                    <div style={{ marginBottom: 10, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.icon}</div>
                                    <CardTitle style={{ padding: 0, margin: 0, fontSize: 10, textAlign: 'center' }}>{item.title}</CardTitle>
                                </div>
                            )
                        })
                    }
                </div>
            </React.Fragment>
        );
    }

    handleClick = (data)=>{
        
        this.props.history.push(data.href)
    }
}

export default withRouter(Dashboard);


const userDashboardMenu = [

    { href: '/user-profile', title: 'Profile', id: 'profile', icon: <UserIcom /> },
    { href: '/user-attempted-shlok', title: 'Approved Shlok', id: 'Attempted_shlok', icon: <UserRight width={40} height={40} /> },
    { href: '/user-non-attempted-shlok', title: 'Non Approved Shlok', id: 'non_attempted_shlok', icon: <UserCross width={30} height={30} /> },
    { href: '/user-payment', title: 'Payment', id: 'payment', icon: <UserPayment width={40} height={40} /> },
    { href: '/user-wallet', title: 'Wallet', id: 'wallet', icon: <UserWallet width={40} height={40} /> },
    // { href: '/user-request', title: 'Request', id: 'requet', icon: <UerRequest width={40} height={40} /> },
    // { href: '', title: {this.state.s}, id: 'requet', icon: <UerRequest width={40} height={40} /> },
    { href: '/user-rewards', title: 'Rewards', id: 'reward', icon: <UserRewards width={40} height={40} /> },
    { href: '/user-kyc-update', title: 'Kyc Update', id: 'kycupdate', icon: <UserKyc width={40} height={40} /> },
    // { href: '/user-donate', title: 'Donate', id: 'donate', icon: <Donate width={40} height={40} /> },
]