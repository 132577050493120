import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { getFromSession } from "../Utils/SessionStorage";

export const userRoleVisibility = ({ user, path }) => {
  let { role } = user || {};
  if (!role) return false;

  let routeWiseRole = {
    "user-payment": "account"
  };

  if (routeWiseRole[path] === role) return true;

  return false;
};

const PrivateRoutes = props => {
  let { component: Component, visible, ...rest } = props || {};
  let user = getFromSession("user") || undefined;
  let admin = getFromSession("admin") || undefined;

  let notRestricted = false;

  if ((user && user.response) || (admin && admin.response)) {
    if (typeof visible === "function") {
      notRestricted = visible({
        user: user && user.data,
        admin: user && user.data,
        path: (props.path && props.path.substring(1, props.path.length)) || ""
      });
    } else if (props.hasOwnProperty("visible")) {
      notRestricted = visible;
    } else {
      notRestricted = true;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        return notRestricted ? (
          <Component {...props} />
        ) : (
          <Redirect to="/user-login" />
        );
      }}
    />
  );
};

export default PrivateRoutes;
