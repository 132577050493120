import React from "react";
import {
  Card,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getFromSession } from "../Utils/SessionStorage";
import { requestServerData } from "../Utils/Config";

class Rewards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: getFromSession("user"),
      loading: true,
      userRewards: []
    };
  }

  componentDidMount() {
    this.getReward();
  }

  render() {
    let { data: { userName = "" } = {} } = this.state.user || {};

    if (this.state.loading) {
      return (
        <div
          style={{
            margin: "15px 0px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <ToastContainer position="top-center" />
          <div class="card">
            <div class="card-body">
              <h3 className="page-center">Rewards</h3>
            </div>
          </div>
          <div
            style={{
              overflow: "auto"
            }}
          >
            <table
              style={{
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#ddd"
              }}
            >
              <tr>
                <th
                  style={{
                    padding: 10
                  }}
                >
                  Chapter Name
                </th>
                <th
                  style={{
                    padding: 10
                  }}
                >
                  Qualifying Date
                </th>
                <th
                  style={{
                    padding: 10
                  }}
                >
                  Certificate
                </th>
                <th
                  style={{
                    padding: 10
                  }}
                >
                  Merchandise
                </th>
                <th
                  style={{
                    padding: 10
                  }}
                >
                  Momento
                </th>
              </tr>

              {(this.state.userRewards &&
                this.state.userRewards.length &&
                this.state.userRewards.map((reward = {}, index) => {
                  let {
                    ContestantName = "",
                    ChapterName = "",
                    qualifyingDate = new Date(),
                    certificateLink = "/user-certificate",
                    merchandiseLink = "/page-under-construction",
                    momentoLink = "/page-under-construction"
                  } = reward || {};
                  return (
                    <tr
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#f9f9f9"
                      }}
                    >
                      <td
                        style={{
                          padding: 10
                        }}
                      >
                        {ChapterName}
                      </td>
                      <td>{qualifyingDate && qualifyingDate.toDateString()}</td>
                      <td>
                        <Link
                          target="_blank"
                          to={{
                            pathname: `${certificateLink}/${ContestantName}/${ChapterName}`,
                          }}
                        >
                          View
                        </Link>
                      </td>
                      <td>
                        <a href={merchandiseLink}>View</a>
                      </td>
                      <td>
                        <a href={momentoLink}>View</a>
                      </td>
                    </tr>
                  );
                })) ||
                null}
            </table>
          </div>
        </React.Fragment>
      );
    }
  }

  getReward = async () => {
    let { data: { uid = "" } = {} } = this.state.user || {};

    this.setState({
      loading: true
    });

    let val_obj = {
      url: `GetReward/GetReward?RegistrationCode=${uid}`, 
      data: "",
      request_type: "GET"
    };

    //  console.log('kyc update', val_obj)
    let response = await requestServerData(val_obj, "user");
    // console.log('user profile data', response.data.data.result)

    if (response.response) {
      let data =
        (response.data.data.result && response.data.data.result) || undefined;

      if (data) {
        this.setState({
          userRewards: data
        });
      }
    } else {
      alert("try again . . .");
    }
    this.setState({
      loading: false
    });
  };
}

export default withRouter(Rewards);
