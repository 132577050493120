import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const date = new Date();
class PendingPayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: "",
            start: this.formatDate(date),
            end: this.formatDate(date)

        };
    }
    componentDidMount() {

        this.getPendingPayouts()

    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    render() {

        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer position="top-center" />
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Pending Payout</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20 ,flexWrap:"wrap"}}>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',flexDirection:"row",flexWrap:"wrap" }}>
                            <div style={{ marginRight: 20,whiteSpace:"nowrap",width:window.innerWidth < 600 ? "100%" :"none" }}>
                                Select Date
                            </div>
                            <div
                            >
                            <div>Start:</div><Input value={this.state.start} type="date" name={'start'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                            <div
                            >
                            <div>End:</div><Input value={this.state.end} type="date" name={'end'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                                {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                            {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                        </div>

                        <div>
                            <Button color={'primary'} onClick={() => this.getPendingPayouts()}>Get Data</Button>
                        </div>
                        {
                            this.state.PendingPayout && this.state.PendingPayout.length > 0 &&
                            <div>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="PendingPayouts"
                                    filename={`${this.state.dateForGetTableData}pendingPayoutsData`}
                                    sheet={`${this.state.dateForGetTableData}pendingPayoutsData`}
                                    buttonText="Download as XLS" />
                            </div>
                        }
                    </div>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <p color="primary" >Total Achiever : {this.state.totalContestnentPayout} </p>
                                    <div className="table-responsive">
                                    <MDBDataTable
                                        id={'pendingPayouts'}
                                        paging={true}
                                        striped
                                        bordered
                                        hover
                                        data={this.state.PendingPayoutsData}
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    handleChangeDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    donwloadExcel = () => {

        if (this.state.PendingPayout && this.state.PendingPayout.length > 0) {

        }
    }

    changestatus = async (PhId) => {

        this.setState({
            loading: true
        })
        let data = [{PhId:PhId}];
        let val_obj = {
            url: `ApprovedShloks/ClaimPayment`,
            data: data,
            request_type: 'POST'
        }
       // console.log('val obj', val_obj)
        let response = await requestServerData(val_obj, 'admin')
        //console.log('pendign payout list', response.data.data.result)

        if (response.response) {

            toast.success("Payout updated successfully !")
            this.getPendingPayouts()
        } else {

           alert('try again . . .')
        }


    }


    getPendingPayouts = async () => {

        this.setState({
            loading: true
        })


        let postdata = {
            "RegCode": "",
            "PayStatus": "UnPaid",
            "FirstDate": this.state.start,
            "SecondDate": this.state.end
        }
        let val_obj = {
            url: `ApprovedShloks/PaymentHistory`,
            data: postdata,
            request_type: 'POST'
        }


        // let val_obj = {
        //     url: `ApprovedShloks/GetShlokSummary?fromDate=${date1}&ToDate=${date2}&PaymentStatus=${ps}`,
        //     data: '',
        //     request_type: 'GET'
        // }
        // alert(val_obj);
      //  console.log('pending val_obj', val_obj)
        let response = await requestServerData(val_obj, 'admin')
      //  console.log('pending payout list', response.data.data.result)

        if (response.response) {

            const data = {
                columns: [
                    { label: 'Payment Requested Date', field: 'PaymentRequestedDate', },
                    { label: 'PhId', field: 'PhId', },
                    { label: 'Registration No', field: 'RegistrationCode', },
                    { label: 'Name', field: 'ContestantName', },
                    { label: 'Mobile No', field: 'MobileNo', },                  
                    { label: 'Account No', field: 'AccountNo', },
                    { label: 'IFSC code', field: 'IFSCcode', },
                    { label: 'Account Holder Name', field: 'AccountHolderName', },
                    { label: 'Amount', field: 'Amount', },
                    { label: 'Action', field: 'Action', }
                ],
                rows: this.assemblePosts(response.data.data),
            }
            this.setState({
                PendingPayoutsData: data,
                totalContestnentPayout: response.data.data.result.length,
                PendingPayout: response.data.data.result, loading: false
            })
            console.log("tpp", this.state.totalPendingPayout);
            // toast.success("Success !")

        } else {

            toast.error("Error !")
            this.props.history.push('/')
        }

    }

    assemblePosts = (ContestnentData) => {

        let posts = ContestnentData.result && ContestnentData.result.map((post) => {
            return (
                {
                    

                    PaymentRequestedDate: post.PaymentRequestedDate.split('T')[0],
                    PhId: post.PhId,
                    RegistrationCode: post.RegistrationCode,
                    ContestantName: post.ContestantName,
                    MobileNo: post.MobileNo,                  
                    AccountNo:post.AccountNo,
                    IFSCcode: post.IFSCcode,
                    AccountHolderName:post.AccountHolderName,
                    Amount: post.TotalAmount,                
                    Action: <div className="action-icons">
                        <Button class="btn btn-outline-success" onClick={() => this.changestatus(post.PhId)} >Pay Now</Button>
                    </div>
                }

            )

        });

        return posts;
    }

}

export default withRouter(PendingPayout);