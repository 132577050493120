import React, { Component } from "react";
import { Badge, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import AddContestent from '../Admin/AddContestent'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ContactUsEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {

        this.handleGetDataForTable()
    }
    donwloadExcel = () => {

        if (this.state.ContactUs && this.state.ContactUs.length > 0) {

        }
    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    render() {

        if (this.state.loading) {

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer
                        position="top-center"
                    />
                    <Row>
                        {/* <DashboardMini/> */}

                    </Row>
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">ContactUs Details</h3>
                                    <hr />
                                    <Row className="align-items-center">
                                        {/* <h4 className="page-center">
                                            Total :
                                            {this.state.ContestnentDataCount}
                                        </h4> */}
                                        <Col className="d-flex justify-content-end">
                                            {/* <Button className="align-self-right" color="info" onClick={() => this.handleClick('add')}>Add new </Button> */}
                                            {/* <Button className="align-self-right" color="info" >Add new </Button> */}
                                        </Col>
                                        <Col>
                                        
                                     
                                        <div>
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="download-table-xls-button"
                                                table="ContactUs"
                                                filename={`${this.state.dateForGetTableData}ContactUsData`}
                                                sheet={`${this.state.dateForGetTableData}ContactUsData`}
                                                buttonText="Download as XLS" />
                                        </div>
                                    </Col>
                                    </Row>
                              
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />

                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                <div className="table-responsive">
                                    <MDBDataTable
                                        id={'ContactUs'}
                                        // paging={true}
                                        striped
                                        bordered
                                        entriesOptions={[10, 50, 100, 1000]}
                                        hover
                                        width={50}
                                        data={this.state.ContactUs}
                                    />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }

    assemblePosts = (ContactUs) => {

        let posts = ContactUs && ContactUs.result.map((post) => {

            // let date = post.CreatedDate && post.CreatedDate.split('T')[0]

            return (
                {
                Date:post.CreatedDate.split('T')[0],
                 Name: post.Name,
                 RegistrationCode: post.RegistrationCode,
                 ContactNumber: post.ContactNumber,
                 Feedback: post.Feedback,
                //  Status:post.Status
                }

            )

        });

        return posts;
    }

    // handleClick = (key, data) => {

    //     switch (key) {

    //         case 'add':

    //             this.props.history.push('/donate')
    //             break;
    //     }
    // }


    handleGetDataForTable = async () => {

        this.setState({
            loading: true
        })
        let val_obj = {
            url: 'ContactUs/GetContactUs',
            data: '',
            request_type: 'GET'
        }
        let response = await requestServerData(val_obj, 'admin')

        console.log('donation list', response.data.data.result)
        if (response.response) {

            const data = {
                columns: [
                    { label: 'Date', field: 'Date', },

                    { label: 'Name', field: 'Name', },
                    { label: 'Registration Code', field: 'ContactNumber', },
                    { label: 'Contact Number', field: 'RegistrationCode', },
                    { label: 'Feedback', field: 'Feedback', },
                    // { label: 'Status', field: 'Status', }

                   ],

                rows: this.assemblePosts(response.data.data),
            }
            this.setState({ ContactUs: data, DonationDataCount: response.data.data.result.length })
            //      toast.success("Success !")

        } else {

            alert('server error')
            this.props.history.push('/')
        }
        this.setState({ loading: false })
    }



}
export default withRouter(ContactUsEntry);