import React, { Component } from "react";
import {
  Card,
  Button,
  Input,
  Spinner,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import NativeDb from "../Utils/nativeDb.json";
import { requestServerData } from "../Utils/Config";
import { withRouter } from "react-router-dom";
import Terms from "../Common/Terms";
import { ToastContainer, toast } from "react-toastify";
import {
  emailVerifier,
  AllowOnlyNumber,
  upiIdCheck
} from "../Validation/Validation";
import { getFromSession } from "../Utils/SessionStorage";
import UserIcon from "../Assets/Images/userIcom";
import Edit from "../Assets/Images/edit.png";
import Update from "../Assets/Images/update.png";
import Close from "../Assets/Images/close.png";

import axios from "axios";

const date = new Date();
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: "",
      selectedDistrict: "",
      selectedAge: "",
      contestentID: props.contestentID || "",
      pathname: window.location.pathname,
      target_native_id: "",
      verifier: Math.floor(Math.random() * 10000),
      today:
        date.getFullYear() +
        "-" +
        "0" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate(),
      user: getFromSession("user"),
      imageUrl: null,
      imagePreviewUrl: null,
      imageChange: false
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  render() {
    let avatarImageSource = this.state.imagePreviewUrl || this.state.imageUrl;
    return (
      <div style={{ width: "100%" }}>
        <div class="card" style={{ padding: "20px" }}>
          <div>
            <ToastContainer position="top-center" />
            <h2>Profile</h2>

            <FormGroup>
              <div
                style={{
                  width: 100,
                  height: 100,
                  borderColor: "#676767",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderRadius: "100%",
                  overflow: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative"
                }}
              >
                {!avatarImageSource ? (
                  <UserIcon />
                ) : (
                  <img
                    src={avatarImageSource}
                    style={{
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      borderRadius: "100%",
                      textAlign: "center"
                    }}
                    onError={e => this.handleImageError(e)}
                  />
                )}
                {!this.state.imageChange ? (
                  <label
                    for="img"
                    title="edit"
                    style={{
                      position: "absolute",
                      top: 2,
                      right: 2,
                      cursor: "pointer",
                      width: 25
                    }}
                  >
                    <img src={Edit} style={{ width: "100%" }} />
                  </label>
                ) : (
                  <>
                    <div
                      title="update"
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 2,
                        cursor: "pointer",
                        width: 25
                      }}
                    >
                      <img
                        src={Update}
                        style={{ width: "100%" }}
                        onClick={e => this.handleUpload(e)}
                      />
                    </div>
                    <div
                      title="close"
                      style={{
                        position: "absolute",
                        bottom: 2,
                        right: 2,
                        cursor: "pointer",
                        width: 25
                      }}
                    >
                      <img
                        src={Close}
                        style={{ width: "100%" }}
                        onClick={e => this.handleUndoImage(e)}
                      />
                    </div>
                  </>
                )}
              </div>
              <input
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <Label>RegistrationCode</Label>
              <Input
                type="text"
                name="registerCode"
                id="registerCode"
                value={this.state.registerCode}
                onChange={e => this.handleChange(e)}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="name"
                name="name"
                id="name"
                placeholder="Your Full Name *"
                value={this.state.name}
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Id *"
                value={this.state.email}
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>

            <div style={{ display: "flex", alignItems: "center" }}>
              <FormGroup style={{ maxWidth: "70%", marginRight: "25px" }}>
                <Label>Date of birth</Label>
                <Input
                  type="date"
                  name="dob"
                  id="dob"
                  value={this.state.dob}
                  max={this.state.today}
                  onChange={e => this.handleChange(e)}
                />
              </FormGroup>

              <Label>Age : {this.state.selectedAge}</Label>
            </div>

            <FormGroup
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center"
              }}
            >
              <Label>Gender</Label>
              <div>
                <Input
                  type="radio"
                  name="male"
                  id="male"
                  checked={this.getGenderValue("male")}
                  onChange={e => this.handleCheckBox(e)}
                />
                <Label>Male</Label>
              </div>
              <div>
                <Input
                  type="radio"
                  name="female"
                  id="female"
                  checked={this.getGenderValue("female")}
                  onChange={e => this.handleCheckBox(e)}
                />
                <Label>Female</Label>
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Mobile Number</Label>
              <Input
                readOnly
                type="mobile"
                name="mobile"
                id="mobile"
                placeholder="Your mobile Number *"
                value={this.state.mobile}
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Upi ID</Label>
              <Input
                type="upiID"
                name="upiID"
                id="upiID"
                placeholder="Your Upi ID *"
                value={this.state.upiID}
                onChange={e => this.handleChange(e)}
                readOnly
              />
            </FormGroup>
            <FormGroup>
              <Label>Name of Upi ID</Label>
              <Input
                type="nameUpiId"
                name="nameUpiId"
                id="nameUpiId"
                placeholder="Your Upi ID Name *"
                value={this.state.nameUpiId}
                onChange={e => this.handleChange(e)}
                readOnly
              />
            </FormGroup>
            {this.state.target_native_id === "contestent-list" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  margin: "15px 0px"
                }}
              >
                <Label style={{ marginRight: 20 }}>Upi Status</Label>
                <Dropdown
                  isOpen={this.state.isUPI}
                  toggle={() => this.handleClickDropDwon("upi_status")}
                >
                  <DropdownToggle caret>
                    {this.state.UpiStatus === "Pending"
                      ? "Pending"
                      : this.state.UpiStatus === "Verified"
                      ? "Verified"
                      : "Select UPI Status"}
                    {"  "}
                  </DropdownToggle>
                  <DropdownMenu style={{ height: 200, overflow: "scroll" }}>
                    {NativeDb["UpiStatus"].map((item, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            this.setState({ UpiStatus: item });
                          }}
                        >
                          {item}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}

            <button
              type="submit"
              class="btn btn-secondary"
              style={{
                marginTop: "12px",
                width: "100%",
                backgroundColor: "	#9932CC"
              }}
              onClick={() => this.handleSubmit()}
            >
              Update
            </button>
          </div>
          {this.state.loading && (
            <div
              style={{
                margin: "15px 0px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Spinner style={{ width: "3rem", height: "3rem" }} />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleImageError = () => {
    if (this.state.imagePreviewUrl) this.setState({ imagePreviewUrl: null });
    else if (this.state.imageUrl) this.setState({ imageUrl: null });
  };

  handleUndoImage = () => {
      this.setState({imagePreviewUrl:null,imageChange:null})
  }

  handleUpload = async event => {
    this.setState({
      loading: true
    });

    const { img,registerCode } = this.state;

    let data = new FormData();
    data.append("", img);
    data.append("RegNo",registerCode);


    let val_obj = {
      data: data,
      request_type: "POST",
      url: "/uploads/profile"
    };
    // console.log('val_obj', val_obj)
    let response = await requestServerData(val_obj, "user");
    // console.log('update user profile', response)

    if (response.response) {
      toast.success("Updated Successfully.");
      this.getUserData();
    } else {
      toast.error("Error");
    }

    this.setState({
      loading: false
    });
  };

  handleChange = event => {
    if (event.target.name === "gender") {
      this.setState({
        [event.target.name]: event.target.id
      });
    } else if (event.target.name === "dob") {
      var today = new Date();
      let selectedYearArray = event.target.value.split("-");
      let selectedYear = selectedYearArray[0];
      let age = today.getFullYear() - JSON.parse(selectedYear);
      this.setState({
        selectedAge: age,
        [event.target.name]: event.target.value
      });
    } else if (event.target.name === "mobile") {
      if (AllowOnlyNumber(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value
        });
      } else {
        toast.error("only number is allow");
      }
    } else if (event.target.name === "img") {
      let selectedImage = event.target.files[0];
    //   let formData = new FormData();
    //   formData.append(
    //     "profileImage",
    //     selectedImage,
    //     this.state.user.data.uid + ".jpg"
    //   );
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({
          img: selectedImage,
          imagePreviewUrl: reader.result,
          imageChange: true
        });
      };
      if (selectedImage && selectedImage.type.match("image.*")) {
        reader.readAsDataURL(selectedImage);
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  getGenderValue = key => {
    if (key === this.state.gender) {
      return true;
    } else {
      return false;
    }
  };

  handleCheckBox = e => {
    if (e.target.checked) {
      this.setState({
        gender: e.target.name
      });
    }
  };

  getUserData = async () => {
    this.setState({
      loading: true
    });

    let val_obj = {
      url: `Contestent/GetContestentByRegNo?RegNum=${this.state.user.data.uid}`,
      data: "",
      request_type: "GET"
    };

    //  console.log('kyc update', val_obj)
    let response = await requestServerData(val_obj, "user");
    // console.log('user profile data', response.data.data.result)
    if (response.response) {
      let data =
        (response.data.data.result && response.data.data.result) || undefined;

      if (data) {
        this.setState({
          registerCode: data.RegistrationCode,
          name: data.ContestantName,
          mobile: data.MobileNo,
          selectedAge: data.Age,
          email: data.Email,
          nameUpiId: data.NameInUpi,
          upiID: data.UpiId,
          dob: this.formatDate(new Date(data.Dob)),
          gender: data.Gender,
          imageChange: false,
          imageUrl: `https://testwebapi.vedic-wisdom.com/image/contestent/${data.RegistrationCode}.jpg`
        });
      }
    } else {
      alert("try again . . .");
    }
    this.setState({
      loading: false
    });
  };

  formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  handleSubmit = async () => {
    this.setState({
      loading: true
    });
    const {
      registerCode,
      name,
      mobile,
      selectedAge,
      email,
      nameUpiId,
      upiID,
      dob,
      gender,
      img
    } = this.state;

    let data = {
      RegistrationCode: registerCode,
      ContestantName: name,
      MobileNo: mobile,
      Age: selectedAge,
      Email: email,
      NameInUpi: nameUpiId,
      UpiId: upiID,
      Dob: dob,
      Gender: gender
      // Image: img
    };

    let val_obj = {
      data: data,
      request_type: "POST",
      url: "Contestent/UpdateContestent"
    };
    // console.log('val_obj', val_obj)
    let response = await requestServerData(val_obj, "user");
    // console.log('update user profile', response)

    if (response.response) {
      toast.success("Updated Successfully.");
      this.getUserData();
    } else {
      toast.error("Error");
    }
    this.setState({
      loading: false
    });
  };
}

export default withRouter(UserProfile);
