import React from 'react';
import './component/Assets/App.css';
import RoutesPage from './component/Routes/RoutesPage'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'mdbreact/dist/css/mdb.css';


function App() {
  return (
    <React.Fragment>
      <div className="container-scroller">
        <RoutesPage/>     
        {/* <Contestent /> */}
      </div>
    </React.Fragment>
  );
}

export default App;
