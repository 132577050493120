import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalFooter, Spinner, Label, ModalBody, Form, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import { requestServerData } from '../Utils/Config'
import { MDBDataTable, MDBInput } from 'mdbreact';
import { withRouter } from "react-router-dom";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const date = new Date();
class UpdatePayouts extends Component {
    constructor(props) {
        super(props);
        this.editContestent = React.createRef();
        this.state = {
            totalContestnentPayout: "",
            TotalShlokCount: "",
            TotalAmountPaid: 0,
            start: this.formatDate(date),
            end: this.formatDate(date),
            contestents: []
        };
    }
    componentDidMount() {
        this.getContestentPayouts(this.state.today,this.state.today, 'UnPaid')
       
    }
    formatDate= (date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
    render() {
        console.log(this.state.today)

        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: window.innerHeight }}>
                    <div >
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>
                </div>
            )
        }

        return (
            <div className={'top-main'} style={{ height: window.innerHeight }}>
                <div className="content-wrapper">
                    <ToastContainer position="top-center" />
                    <Row>
                        <Col md="12">
                            <div class="card">
                                <div class="card-body">
                                    <h3 className="page-center">Update Payout</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: 20 ,flexWrap:"wrap"}}>
                        {
                            this.state.contestentData && this.state.contestentData.length > 0 &&
                            < div >
                                <Button color={'primary'} onClick={() => this.selectAllCheckBoxes()}>Select All</Button>
                            </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center',flexDirection:"row",flexWrap:"wrap" }}>
                            <div style={{ marginRight: 20,whiteSpace:"nowrap",width:window.innerWidth < 600 ? "100%" :"none" }}>
                                Select Date
                            </div>
                            <div
                            >
                            <div>Start:</div><Input value={this.state.start} type="date" name={'start'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                            <div
                            >
                            <div>End:</div><Input value={this.state.end} type="date" name={'end'} onChange={e => this.handleChangeDate(e)} />
                            </div>
                                {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                            {/* <Input value={this.state.today} type="date" name={'date'} onChange={e => this.handleChangeDate(e)} /> */}
                        </div>
                        <div>
                            <Button color={'primary'} onClick={() =>  this.getContestentPayouts(this.state.start,this.state.end,'UnPaid')}>Get Data</Button>
                        </div>
                        <Button color={'success'} onClick={() => this.handleSubmitToserver()}>Update Payout</Button>
                    </div>
                    <Row className="mb-4">
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    Total Achiever : <b>{this.state.totalContestnentPayout}</b>
                                    {/* Total Shlok Count : { this.state.TotalShlokCount} */}
                                    &nbsp;&nbsp;&nbsp;&nbsp; Total Amount : <b>{ this.state.TotalAmountPaid}</b>
                                    <div className="table-responsive">
                                    <MDBDataTable
                                        id={'contestentPayouts'}
                                        paging={false}
                                        striped
                                        bordered
                                        hover
                                        data={this.state.ContestnentPayoutsData}
                                    />
                                    </div>
                                </CardBody>
                                <div>
                                    {/* <Button color={'primary'} onClick={() => this.handleSubmitToserver()}>Update Data</Button> */}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }

    selectAllCheckBoxes = () => {

        this.state.contestentData && this.state.contestentData.map((item, index) => {

            document.getElementById(item.RegistrationCode).checked = true;
        })
        this.setState({
            contestents: this.state.contestentData
        })

    }

    handleChangeDate = (e) => {

        if(e.target.name==='start')
        {
            this.setState({           
                start: e.target.value  
            })
        }
        if(e.target.name==='end')
        {
            this.setState({                  
                end: e.target.value                  
            })
        }
       
    }

    handleClickCheckBox = async (e, data) => {
   
        if (e.target.checked) {

            this.setState({
                [data.RegistrationCode]: true
            })
            document.getElementById(data.RegistrationCode).checked = true;
            this.updateDataInArray('add', data)
        } else {

            this.setState({
                [data.RegistrationCode]: false
            })
            document.getElementById(data.RegistrationCode).checked = false;
            this.updateDataInArray('remove', data)
        }
    }

    updateDataInArray = async (action, data) => {

        let contestent = this.state.contestents
        if (action === 'add') {
            await contestent.push({PhId:data.PhId})
        } else {

            let filterData = await contestent.filter(item => item.PhId !== data.PhId)
            contestent = filterData
        }
        this.setState({
            contestents: contestent
        })
    }

    getContestentPayouts =async (date1,date2, type) => {

        this.setState({
            loading: true
        })

        let  postdata = {
            "RegCode": "",
            "PayStatus": type,
            "FirstDate": date1,
            "SecondDate": date2
        }
        let val_obj = {
            url: `ApprovedShloks/PaymentHistory`,
            data: postdata,
            request_type: 'POST'
        }
      

        let response = await requestServerData(val_obj, 'admin')

        await response.data.data.result && response.data.data.result.map((item, index) => {

            this.setState({ [item.RegistrationCode]: true })
        })
        if (response.response) {
            let totalAmount = 0

            const data = {
                columns: [
                    // { label: 'ContestantId', field: 'ContestantId', },
                    // { label: 'Registration Date', field: 'RegistrationDate', },
                    { label: 'Action', field: 'Action', },
                    { label: 'Payment Requested Date', field: 'PaymentRequestedDate', },
                    { label: 'PhId', field: 'PhId', },
                    { label: 'Registration No', field: 'RegistrationCode', },
                    { label: 'Name', field: 'ContestantName', },
                    { label: 'Mobile No', field: 'MobileNo', },
                    { label: 'Bank Name', field: 'BankName', },
                    { label: 'Account No', field: 'AccountNo', },
                    { label: 'IFSC code', field: 'IFSCcode', },
                    { label: 'Account Holder Name', field: 'AccountHolderName', },
                    
                    { label: 'Upi ID', field: 'UpiId', },
                    // { label: 'Name In Upi ID', field: 'NameInUpi', },
                    { label: 'UPI Status', field: 'UpiStatus', },             
                   
                    { label: 'Total Amount', field: 'TotalAmount', },
                    // { label: 'Remuneration', field: 'OptOutRemuneration', },
                    // { label: 'Action', field: 'Action', }
                ],
                rows: this.assemblePosts(response.data.data),
            }
            response.data.data.result && response.data.data.result.map((item, index) => {
                if (item.TotalAmount > 0) {
                    totalAmount = totalAmount + item.TotalAmount
                }
            })
            this.setState({
                ContestnentPayoutsData: data,
                totalContestnentPayout: response.data.data.result.length,
                TotalAmountPaid: totalAmount,
                 loading: false,
                contestentData: response.data.data.result
            })
           

        } else {
            this.setState({
                ContestnentPayoutsData: [],
                totalContestnentPayout: 0,
                TotalAmountPaid: 0,
                 loading: false,
                contestentData:[]
            })
            toast.error("Error !")
            this.props.history.push('/')
        }

    }

    assemblePosts = (ContestnentData) => {

        let posts = ContestnentData.result && ContestnentData.result.map((post, index) => {

            return (
                {
                    // ContestantId: post.ContestantId,
                    // RegistrationDate: post.CreatedDate,
                    Action:
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Input type="checkbox" id={post.RegistrationCode} onClick={(e) => { this.handleClickCheckBox(e, post) }} />
                        </div>
                    ,
                    PaymentRequestedDate: post.PaymentRequestedDate.split('T')[0],
                    PhId: post.PhId,
                    RegistrationCode: post.RegistrationCode,
                    ContestantName: post.ContestantName,
                    MobileNo: post.MobileNo,
                    BankName: post.BankName,
                    AccountNo:post.AccountNo,
                    IFSCcode: post.IFSCcode,
                    AccountHolderName:post.AccountHolderName,
                    // NameInUpi: post.NameInUpi,
                    UpiId:post.UpiId,
                    UpiStatus: post.UpiStatus,                                   
                  //  ShlokCount: post.ShlokCount,
                  TotalAmount: post.TotalAmount ,
                //   PaidDate: post.PaidDate?.split('T')[0]  
                    // OptOutRemuneration: <Button className="btn-sm" color={post.OptOutRemuneration === true ? 'success' : 'danger '} > {post.OptOutRemuneration === true ? "Need Remenaration" : 'No Remenaration'}  </Button>,
                    // Action: <div className="action-icons">

                    //     <Button color="primary mr-2 " onClick={() => this.handleClick('edit', post)} >Edit</Button>
                    //     <Button class="btn btn-outline-danger" onClick={() => this.handleClick('approved', post)} >Approved Shlok</Button>
                    // </div>
                }

            )

        });

        return posts;
    }


    handleSubmitToserver = async () => {

        this.setState({
            loading: true
        })
        let obj =this.state.contestents;
       // console.log('contestent obj', JSON.stringify(obj))

        let val_obj = {
            url: `ApprovedShloks/ClaimPayment`,
            data: obj,
            request_type: 'POST'
        }
        let response = await requestServerData(val_obj, 'admin')
       
      
        if (response.response) {

            toast.success("Payout updated successfully !")
            this.getContestentPayouts(this.state.today, 'UnPaid')
        } else {

            toast.error("error !")
        }
        this.setState({
            loading: false
        })
    }
}

export default withRouter(UpdatePayouts);