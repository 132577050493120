import React from "react";
import ContestentList from "../Admin/ContestentList";
import AddContestent from "../Admin/AddContestent";
import ApprovedSlok from "../Admin/ApprovedSlok";
import ContestentPayout from "../Admin/ContestentPayout";
import UpdatePayouts from "../Admin/UpdatePayouts";
import Dashboard from "../Admin/Dashboard";
import DashboardMini from "../Admin/DashboardMini";
import PendingPayout from "../Admin/PendingPayout";
import Sidebar from "../Common/Sidebar";
import Donate from "../Admin/Donate";
import DonationList from "../Admin/DonationList";
import ContactUsEntry from "../Admin/ContactUsEntry";
import AddUserType from "../Admin/AddUserType";
import AddUserTypeForm from "../Admin/AddUserTypeForm";
import AddUser from "../Admin/AddUser";
import ViewWallet from "../Admin/ViewWallet";

import ViewapprovedSlok from "../Admin/ViewapprovedSlok";
import NewapproverSlok from "../Admin/NewapproverSlok";
import { getFromSession, removeSessionStorage } from "../Utils/SessionStorage";
import HomePage from "../Common/HomePage";

class AdminRoutes extends React.Component {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // lifecycle

  constructor(props) {
    super(props);
    this.state = {
      target_native_id: "",
      isSlidBar: false
    };
  }

  componentDidMount() {
    this.setupComponent();
  }

  componentDidUpdate(revProps, preState) {
    if (this.state.target_native_id !== preState.target_native_id) {
      this.setupComponent();
    }
  }

  handleSlidebar = () => {
    this.setState(prevState => ({
      isSlidBar: !prevState.isSlidBar
    }));
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // render

  render() {
    const { target_native_id, isSlidBar } = this.state;

    let admin = getFromSession("admin") || undefined;
    if (admin && admin.response) {
      return (
        <React.Fragment>
          <div>
            <div
              className={"top-header"}
              style={{
                position: "fixed",
                maxHeight: 50
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row"
                }}
              >
                <div
                  className="icon-bar-wrap"
                  style={{ background: isSlidBar ? "#61dafb" : "transparent" }}
                  onClick={e => this.handleSlidebar(e)}
                >
                  <div className="icon-bar"></div>
                  <div className="icon-bar"></div>
                  <div className="icon-bar"></div>
                </div>
                <p
                  style={{
                    color: "#111",
                    fontSize: "30px",
                    fontWeight: "lighter",
                    margin: 0,
                    padding: 0
                  }}
                >
                  Vedic Wisdom
                </p>
              </div>
            </div>
            {isSlidBar && <Sidebar />}
            <div className={"main-page"}>
              <div className={"main-page-middle"}>
                {target_native_id === "pending-payout" && <PendingPayout />}

                {target_native_id === "dashbordmini" && <DashboardMini />}
                {target_native_id === "dashbord" && <Dashboard />}
                {target_native_id === "contestent-list" && <ContestentList />}
                {target_native_id === "add-contestent" && <AddContestent />}
                {target_native_id === "edit-contestent" && <AddContestent />}
                {target_native_id === "approved-slok" && <ApprovedSlok />}
                {target_native_id === "view-approved-slok" && (
                  <ViewapprovedSlok />
                )}
                {target_native_id === "contestent-payout" && (
                  <ContestentPayout />
                )}
                {target_native_id === "contestent-update-payout" && (
                  <UpdatePayouts />
                )}
                {target_native_id === "donate" && <Donate />}

                {target_native_id === "donation" && <DonationList />}
                {target_native_id === "contactus-entry" && <ContactUsEntry />}
                {target_native_id === "add-user-type" && <AddUserType />}
                {target_native_id === "add-user-type-form" && (
                  <AddUserTypeForm />
                )}
                {target_native_id === "wallet" && <ViewWallet />}

                {target_native_id === "add-user" && <AddUser />}
                {target_native_id === "new-approved-shlok" && (
                  <NewapproverSlok />
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      this.props.history.push("/");
      return <React.Fragment />;
    }
  }

  setupComponent = () => {
    let current_path = window.location.pathname;
    let url_arr = current_path.split("/");
    let target = url_arr[1];
    this.setState({
      target_native_id: target
    });
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux

export default AdminRoutes;
